<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 21">
    <path
      d="M21,10H3M16,2V6M8,2V6M7.8,22h8.4a7.453,7.453,0,0,0,3.162-.327,3,3,0,0,0,1.311-1.311A7.453,7.453,0,0,0,21,17.2V8.8a7.453,7.453,0,0,0-.327-3.162,3,3,0,0,0-1.311-1.311A7.453,7.453,0,0,0,16.2,4H7.8a7.453,7.453,0,0,0-3.162.327A3,3,0,0,0,3.327,5.638,7.453,7.453,0,0,0,3,8.8v8.4a7.453,7.453,0,0,0,.327,3.162,3,3,0,0,0,1.311,1.311A7.453,7.453,0,0,0,7.8,22Z"
      transform="translate(-2.5 -1.5)"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    />
  </svg>
</template>
